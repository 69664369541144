import FallbackComponent from "./FallbackComponent"
import DesktopOnlyFallbackImg from '../../images/icons/fallback/desktop-compatible-fallback.svg'

const DesktopOnlyFallback = () => {
  return (
    <FallbackComponent
      image={DesktopOnlyFallbackImg}
      title="For your convenience, please go to the desktop version"
    />
  )
}

export default DesktopOnlyFallback
