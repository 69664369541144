import { useDispatch, useSelector } from "react-redux";
import { useEffect, useMemo, useState } from "react";
import cn from "classnames";
import Split from "react-split-grid";
//components
import Header from "../components/header";
import { Editor } from "../components/code-editor";
import { MeetingSidebar } from "../components/sidebar/meeting-sidebar";
import { TaskDescription } from "../components/task-description";
//store
import { setModal, setReadOnly } from "../store/editor/editor.actions";
import {
  resetAssessmentState,
  setAssessmentTask,
  setDuration,
  setIsOnline,
} from "../store/assessment/assessment.actions";
import { assessmentSelectors } from "../store/assessment/assessment.selectors";
import { editorSelectors } from "../store/editor/editor.selectors";
//types
import * as types from "../types/editor";
//hooks
import useSettings from "../hooks/editor/use-settings";
import { useWindowSize } from "../hooks/common/use-window-size";
import fetchData, { patchData } from "../hooks/api/postData";
import { getEnv } from "@urecruits/api";
import { useParams, useNavigate } from "react-router-dom";
import VideoMeetLoader from "../components/video-meeting/Loader/VideoMeetLoader";
import React from "react";
import { useHasPermission, AuthGuard, DesktopOnlyPage } from "@ucrecruits/globalstyle/src/ucrecruits-globalstyle";
const { API_ASSESSMENT } = getEnv()

const OfflineCodingPage = (props) => {
  const dispatch = useDispatch();
  const { width } = useWindowSize();
  const { isOnline, duration, isStartedSolving } = useSelector(assessmentSelectors.getAllState);
  const { settings } = useSelector(editorSelectors.getAllState);
  const [isLoading, setIsLoading] = useState(false);
  const [isTestGiven, setIsTestGiven] = useState(false);
  const [columns, setColumns] = useState("400px 1px 1fr");
  const { assessmentId, jobId } = useParams()
  const navigate = useNavigate()

  const { checkUserPermission } = useHasPermission()

  useSettings();

  useEffect(() => {
    async function getData() {
      setIsLoading(true);
      try {
        const data = await fetchData(`${API_ASSESSMENT}/api/test-manager?jobId=${jobId}&assessmentType=Coding Assessment`)
        if (data) {
          setIsLoading(false);
          if (data?.testStatus === 'Not Started' && new Date(data?.deadline).getTime() > new Date().getTime()) {
            dispatch(setDuration(data?.timeDuration));
            setIsTestGiven(false)
          } else if (data?.testStatus === 'In Progress' && new Date(data?.deadline).getTime() > new Date().getTime() && !isStartedSolving) {
            dispatch(setModal(types.CodingModalsEnum.inProgress));
          } else {
            dispatch(setModal(types.CodingModalsEnum.deadline));
          }
        }
      } catch (error) {
        console.log("Error getting candidate assessment data :", error)
      } finally {
        setIsLoading(false)
      }
    }
    checkUserPermission('candidate') && getData()
  }, [assessmentId]);

  useEffect(() => {
    checkUserPermission("candidate") && fetchData(`${API_ASSESSMENT}/api/take-home-task/${assessmentId}`).then((data) => {
      dispatch(setAssessmentTask(data));
    })
    dispatch(setReadOnly(false));
    dispatch(setIsOnline(false));
    return () => {
      dispatch(resetAssessmentState());
    };
  }, [assessmentId]);

  const handleDrag = (direction, track, style) => {
    setColumns(style);
  };

  useEffect(() => {
    const handleLeaveButtonClick = async () => {
      const confirmed = window.confirm("Are you sure you want to leave?");
    };
    window.addEventListener("beforeunload", handleLeaveButtonClick);

    return () => {
      window.removeEventListener("beforeunload", handleLeaveButtonClick);
    };
  }, []);

  const onSubmitTask = () => {
    patchData(`${API_ASSESSMENT}/api/test-manager?jobId=${jobId}&assessmentType=Coding Assessment&testStatus=Completed`, {});
    return;
  }
  const columnMinSizes = useMemo(() => {
    const editorWidth = width - width * 0.4;
    const taskDescriptionWidth = 400;
    if (settings.view === types.viewEnum.left) {
      setColumns("400px 1px 1fr");
      return { [0]: taskDescriptionWidth, [2]: editorWidth };
    } else {
      setColumns("1fr 1px 400px");
      return { [0]: editorWidth, [2]: taskDescriptionWidth };
    }
  }, [settings.view, width]);

  return <>
    {
      width < 991 ?
        <DesktopOnlyPage />
        :
        <AuthGuard module='candidate'>
          {isLoading ? <>
            <VideoMeetLoader />
          </> :
            <div className="codding">
              <div className="codding__wrapper">
                <Header />
                <div className={cn("codding__content-wrapper", settings.view)}>
                  <Split
                    gridTemplateColumns={columns}
                    onDrag={handleDrag}
                    cursor="col-resize"
                    columnMinSizes={columnMinSizes}
                    // @ts-ignore
                    render={({ getGridProps, getGutterProps }) => (
                      <div
                        className={`split-grid ${settings.view}`}
                        {...getGridProps()}
                      >
                        <TaskDescription />
                        <div className="gutterGrid" {...getGutterProps("column", 1)} />
                        <Editor isWidthChanged={columns} onSubmit={onSubmitTask} />
                      </div>
                    )}
                  />
                  {isOnline && <MeetingSidebar />}
                </div>
              </div>
            </div>}
        </AuthGuard>
    }
  </>;
};

export default React.memo(OfflineCodingPage)