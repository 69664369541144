import { useCallback, useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import CodeEditor from "../_elements/CodeEditor";
import firebase from "../../config/firebaseFirestore";
import { editorSelectors } from "../../store/editor/editor.selectors";
import { fromMonaco, PlainTextOperation } from "@hackerrank/firepad";
import * as monaco from "monaco-editor";
import { SetCurrentUser, setMarker } from "../../store/editor/editor.actions";
import { useDispatch } from "react-redux";
import { setAllParticipants } from "../../store/video-meeting/assessment.actions";
import { AuthGuard } from "@ucrecruits/globalstyle/src/ucrecruits-globalstyle";
import { assessmentSelectors } from "../../store/assessment/assessment.selectors";

export const EditorPlayback = ({ editorContainerRef, isWidthChanged }) => {
  const [actionBTN, setActionBTN] = useState("");
  const [isStart, setIsStart] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(0);
  const isPlayingRef = useRef(false);
  const firepadRef = useRef<ReturnType<typeof fromMonaco> | null>(null);
  const playbackTimeoutRef = useRef<NodeJS.Timeout | null>(null);
  const revisionsByTimestampRef = useRef({});
  const revisionByUserId = useRef({});
  const dispatch = useDispatch();
  const [playbackId,setPlaybackId] = useState(null);
  const { activeTask } = useSelector(assessmentSelectors.getAllState);

  const { collectionId,userId,jobId,assessmentId } = useParams();
  
  const { editor, remoteCursorManager } = useSelector(editorSelectors.getAllState);
  const dbRef = playbackId=> firebase.database().ref(`${playbackId}`);

  useEffect(()=>{
    if(activeTask){
      const assessmentType =collectionId.split('-').slice(0,2).join('-')
      if(assessmentType === 'live-coding'){
        setPlaybackId(collectionId)
      }else if(assessmentType === 'take-home'){
        setPlaybackId(assessmentType+"-"+jobId+userId+assessmentId+activeTask?.data.id)
      }
    }
  },[jobId,userId,collectionId,assessmentId,activeTask])

  useEffect(()=>{
    playbackId && dbRef && dbRef(playbackId).child('users').once("value")
    .then((snapshot) => {
      const userData=snapshot.val()
      if (!userData) {
        console.warn("No user data found for playbackId:", playbackId);
        return;
      }

      const user=[]
      for (let key in userData) {
        user.push(userData[key]?.name)
      }
      userData && dispatch(setAllParticipants(user))
    })
  },[playbackId])

  const fetchRevisions = async (firebaseRef) => {
    return new Promise((resolve, reject) => {
      firebaseRef.child('history').once("value", function (snapshot) {
        const revisions = snapshot.val();
        const revisionsByTimestamp = {};
        let userId=[]
        for (let key in revisions) {          
          const revision = revisions[key];
          const operation = PlainTextOperation.fromJSON(revision.o);
          revisionsByTimestamp[revision.t] = operation;
          userId.push(revision?.a)
        }
        resolve({revisionsByTimestamp,userId});
      });
    });
  };

  const textForRevision = (revision, revisions) => {
    let document: any = new PlainTextOperation();
    const keys = Object.keys(revisions).sort();
    for (let key of keys) {
      const operation = revisions[key];
      document = document.compose(operation);
      if (key === revision) {
        break;
      }
    }
    return document._ops.length ? document.apply("") : null;
  };
  const calculateNewCursorPosition = (newText, currentPosition) => {
    const newPosition = currentPosition
      ? new monaco.Position(currentPosition.lineNumber, currentPosition.column + 1)
      : null;

    return newPosition;
  };
  const applyRevisionWithDelay = useCallback((index, revisions, userId) => {
    const revisionKeys = Object.keys(revisions).sort();
    
    if (index < revisionKeys.length && isPlayingRef.current) {
      const timestamp = revisionKeys[index];
      const text = textForRevision(timestamp, revisions);
  
      playbackId &&
        dbRef(playbackId)
          .child("users")
          .once("value")
          .then((snapshot) => {
            const userSnapshot = snapshot.val();
            if (userSnapshot) {
              const currentUser = userSnapshot[userId[index]]?.name || "Unknown User";
              dispatch(SetCurrentUser(currentUser));
            }
  
            // Apply text to editor
            editor.setValue(text);
            const newPosition = calculateNewCursorPosition(text, editor.getPosition());
            if (newPosition) {
              editor.setPosition(newPosition);
              editor.revealPositionInCenter(newPosition);
            }
  
            // Move to next revision after delay
            setCurrentIndex((prevIndex) => prevIndex + 1);
            playbackTimeoutRef.current = setTimeout(() => {
              applyRevisionWithDelay(index + 1, revisions, userId);
            }, 5);
          })
          .catch((error) => {
            console.error("Error fetching user data:", error);
            const currentPosition = editor.getPosition();
          editor.setValue(text);
          const newPosition = calculateNewCursorPosition(text, currentPosition);
          if (newPosition) {
            editor.setPosition(newPosition);
            editor.revealPositionInCenter(newPosition);
            editor.focus();
          }
  
          setCurrentIndex(index);
  
          playbackTimeoutRef.current = setTimeout(() => {
            applyRevisionWithDelay(index + 1, revisions, userId);
          }, 1);
          });
    } else {
      if(index === Object.keys(revisions).length) {
        setActionBTN("")
        stopPlayback();
        editor.setValue(null);
        setCurrentIndex(0);
      }
      isPlayingRef.current = false;
    }
  }, [playbackId,currentIndex]);
  
  

  const startPlayback = useCallback(async () => {
    const revisionInfo:any = await fetchRevisions(dbRef(playbackId));
    revisionsByTimestampRef.current = revisionInfo?.revisionsByTimestamp;
    revisionByUserId.current = revisionInfo?.userId;
    
    const keys = Object.keys(revisionInfo?.revisionsByTimestamp).sort();
    setIsStart(true);
    setCurrentIndex(0);
    applyRevisionWithDelay(currentIndex, revisionInfo?.revisionsByTimestamp,revisionInfo?.userId);
  },[playbackId]);

  const stopPlayback = useCallback(() => {
    if (playbackTimeoutRef.current) {
      clearTimeout(playbackTimeoutRef.current);
      playbackTimeoutRef.current = null;
    }
  },[playbackId]);

  useEffect(() => {
    if (!playbackId) return;
  
    // Stop any ongoing playback before proceeding
    stopPlayback();
    isPlayingRef.current = false;
  
    // Reset playback controls
    setActionBTN("");
  
    if (editor) {
      editor.setValue(null);
      setCurrentIndex(0);
      const model = editor.getModel();
      if (model) {
        // Clear editor content immediately
        editor.executeEdits("clear", [{ range: model.getFullModelRange(), text: "" }]);
  
        // undo/redo stack is reset
        model.pushStackElement();
        model.pushEditOperations([], [{ range: model.getFullModelRange(), text: "" }], null);
        model.pushStackElement();
  
        setTimeout(() => {
          editor.trigger("manual", "editor.action.formatDocument", {});
        }, 500);
      }
    }
  
    // Fetch users related to the playback
    dbRef(playbackId)
      .child("users")
      .once("value")
      .then((snapshot) => {
        const userData = snapshot.val();
        if (userData && typeof userData === "object") {
          const userList = Object.values(userData).map(
            (user: any) => user?.name || "Unknown User"
          );
          dispatch(setAllParticipants(userList));
        }
      })
      .catch((error) => console.error("Error fetching user data:", error));
  
  }, [playbackId]);

  useEffect(() => {
    if (actionBTN === "start") {
      isPlayingRef.current = true;
      startPlayback();
    } else if (actionBTN === "pause") {
      if (playbackTimeoutRef.current) {
        clearTimeout(playbackTimeoutRef.current);
      }
      isPlayingRef.current = false;
    } else if (actionBTN === "play") {
      isPlayingRef.current = true;
      applyRevisionWithDelay(currentIndex, revisionsByTimestampRef.current,revisionByUserId?.current);
    } else if (actionBTN === "reset") {
      isPlayingRef.current = false;
      stopPlayback();
      editor.setValue(null);
      setCurrentIndex(0);
    } else if (actionBTN === "stop") {
      isPlayingRef.current = false;
      stopPlayback();
    }
  }, [actionBTN]);

  useEffect(() => {
    monaco?.editor?.onDidChangeMarkers(async ([uri]) => {
      const markers = monaco?.editor?.getModelMarkers({ resource: uri });
      dispatch(setMarker(markers));
    });
  }, [dispatch]);

  return (
    <AuthGuard module='assessment' permission='view'>
    <div className="editor">
      <div>
        <Buttons setActionBTN={setActionBTN} actionBTN={actionBTN} />
      </div>
      <CodeEditor editorRef={firepadRef} editorContainerRef={editorContainerRef} isWidthChanged={isWidthChanged} />
    </div>
    </AuthGuard>
  );
};

const Buttons = ({ setActionBTN, actionBTN }) => {
  const { currentUser } = useSelector(editorSelectors.getAllState);
  return (
    <div className="buttons__container">
      <button
        className="c-btn"
        onClick={() => {
          if (actionBTN === "") {
            setActionBTN("start");
          } else if (actionBTN === "start" || actionBTN === "play") {
            setActionBTN("pause");
          } else {
            setActionBTN("play");
          }
        }}
        disabled={actionBTN === "start" || actionBTN === "play"}
      >
        {actionBTN === "" ? "Start" : "Play"}
      </button>
      <button
        className="c-btn"
        onClick={() => {
          if (actionBTN === "stop") {
            setActionBTN("reset");
          } else {
            setActionBTN("stop");
          }
        }}
        disabled={actionBTN === ""}
      >
        {actionBTN === "stop" ? "Reset" : "Stop"}
      </button>
      { (actionBTN === "start" || actionBTN === "play") && currentUser && (
        <span className="typing">
          <span className="c-btn">{`${currentUser} is typing`}
          <span className="loader__dot">.</span><span className="loader__dot">.</span><span className="loader__dot">.</span>
          </span>
         </span>
      )}
    </div>
  );
};

