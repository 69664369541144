import { useDispatch, useSelector } from "react-redux";
import { navigate } from "@reach/router";
import { Modal } from "../../_elements/Modal";
import { editorSelectors } from "../../../store/editor/editor.selectors";
import * as types from "../../../types/editor";
import { useMemo } from "react";
import textData from "./editor-modals-text.json";
import { setModal } from "../../../store/editor/editor.actions";
import KeyboardShortcuts from "./keyboardShortcuts";
import ListOfQuestions from "./listOfQuestions";
import { EditorSettingsModal } from "./editor-settings-modal";
import { SetMeetingStream, SetStream } from "../../../store/video-meeting/assessment.actions";
import { videoMeetingSelectors } from "../../../store/video-meeting/assessment.selectors";
import { setIsEndInterview } from "../../../store/video-meeting/assessment.actions";
import { getEnv } from "@urecruits/api";
import fetchData from "../../../hooks/api/postData";
const congrImage = require("../../../assets/icons/congrad.svg");
const { API_RECRUITMENT } = getEnv()
export const EditorModals = () => {
  const dispatch = useDispatch();
  const { modal } = useSelector(editorSelectors.getAllState);
  const { videoMeetingData } = useSelector(videoMeetingSelectors.getAllState);

  const renderContent = useMemo(() => {
    const data = textData[modal];

    switch (modal) {
      case types.CodingModalsEnum.deadline:
        return {
          title: data.deadlineIsOver,
          body: (
            <>
              <p className="EditorModals__bold">{data.dearCandidate}</p>
              <p className="EditorModals__text">
                {data.assessmentTimeLimitWillBeExpired}
              </p>
              <p className="EditorModals__bold">{data.regards}</p>
              <p className="EditorModals__bold">{data.uRecruitsTeam}</p>
              <button
                className="c-btn c-btn-big c-btn--green"
                onClick={() => { navigate("/") }}
              >
                {data.contactRecruitmentTeam}
              </button>
            </>
          ),
        };
      case types.CodingModalsEnum.inProgress:
        return {
          title: data.deadlineIsOver,
          body: (
            <>
              <p className="EditorModals__bold">{data.dearCandidate}</p>
              <p className="EditorModals__text">
                {data.assessmentTimeLimitWillBeExpired}
              </p>
              <p className="EditorModals__text">
                {data.message}
              </p>
              <p className="EditorModals__bold">{data.regards}</p>
              <p className="EditorModals__bold">{data.uRecruitsTeam}</p>
              <button
                className="c-btn c-btn-big c-btn--green"
                onClick={() => { navigate("/") }}
              >
                {data.contactRecruitmentTeam}
              </button>
            </>
          ),
        };

      case types.CodingModalsEnum.invite:
        return {
          title: data.signInToContinue,
          body: (
            <>
              <p className="EditorModals__text">{data.needToBeLoggedIn}</p>
              <button
                className="c-btn c-btn-big c-btn--green"
                onClick={() => navigate("/login")}
              >
                {data.logIn}
              </button>
              <p className="EditorModals__text">
                {data.dontHaveAccount}
                <span>
                  <a className="EditorModals__link" href="#">
                    {data.signuUp}
                  </a>
                </span>
              </p>
            </>
          ),
        };
      case types.CodingModalsEnum.congratulations:
        return {
          title: data.congratulations,
          body: (
            <>
              <img
                className="EditorModals__image"
                src={congrImage}
                alt="congratulations"
              />
              <p className="EditorModals__text">
                {data.youHaveSuccessfullyCompletedAssignment}
              </p>
              <button
                className="c-btn c-btn-big c-btn--green"
                onClick={() => {
                  dispatch(setModal(null));
                  navigate("/");
                }}
              >
                {data.gotIt}
              </button>
            </>
          ),
        };
      case types.CodingModalsEnum.completed:
        return {
          title: data.completed,
          body: (
            <>
              <img
                className="EditorModals__image"
                src={congrImage}
                alt="completed"
              />
              <p className="EditorModals__text">
                {data.youHaveSuccessfullyCompleted}
              </p>
              <button
                className="c-btn c-btn-big c-btn--green"
                onClick={() => {
                  dispatch(setModal(null));
                  dispatch(SetMeetingStream(false));
                  dispatch(SetStream(null));
                  navigate("/");
                }}
              >
                {data.gotIt}
              </button>
            </>
          ),
        };
      case types.CodingModalsEnum.keyboardShortcuts:
        return {
          title: data.title,
          body: <KeyboardShortcuts textData={data} />,
        };
      case types.CodingModalsEnum.listOfQuestions:
        return {
          title: data.title,
          body: <ListOfQuestions textData={data} />,
        };
      case types.CodingModalsEnum.settings:
        return {
          title: data.title,
          body: <EditorSettingsModal data={data.body} />,
        };
      case types.CodingModalsEnum.endInterview:
        return {
          title: data.title,
          body: (
            <>
              <p className="EditorModals__text">{data.body}</p>
              <div className="EditorModals__actions">
                <button
                  className="button--empty"
                  onClick={() => {
                    videoMeetingData?.room.disconnect();
                    dispatch(setModal(null));
                    dispatch(SetMeetingStream(false));
                    dispatch(SetStream(null));
                    navigate("/");
                  }}
                >
                  Leave
                </button>
                <button
                  className="button--filled"
                  onClick={() => {
                    dispatch(setIsEndInterview(true))
                    navigate("/");
                  }}
                >
                  End Interview
                </button>
              </div>
            </>
          )
        };
      case types.CodingModalsEnum.leaveMeeting:
        return {
          title: data.title,
          body: (
            <>
              <p className="EditorModals__text">{data.body}</p>
              <div className="EditorModals__actions">
                <button
                  className="button--empty"
                  onClick={() => {
                    videoMeetingData?.room.disconnect();
                    dispatch(setModal(null));
                    dispatch(SetMeetingStream(false));
                    dispatch(SetStream(null));
                    navigate("/");
                  }}
                >
                  Exit
                </button>
                <button
                  className="button--filled"
                  onClick={() => {
                    videoMeetingData?.room.disconnect();
                    // Mark the round as complete
                    fetchData(`${API_RECRUITMENT}/api/twilio/video-complete?roomName=${videoMeetingData?.room?.name}`)
                      .then(data => data);
                    dispatch(setModal(null));
                    dispatch(SetMeetingStream(false));
                    dispatch(SetStream(null));
                    navigate("/");
                  }}
                >
                  End Interview
                </button>
              </div>
            </>

          )
        };

      case types.CodingModalsEnum.interviewEnded:
        return {
          title: data.title,
          body: (
            <>
              <p className="EditorModals__text">{data.body}</p>
              <button
                className="c-btn c-btn-big c-btn--green"
                onClick={() => {
                  videoMeetingData?.room.disconnect();
                  dispatch(setModal(null));
                  dispatch(SetMeetingStream(false));
                  dispatch(SetStream(null));
                  navigate("/");
                }}
              >
                Leave page
              </button>
            </>
          )
        }
      default:
        return {
          title: null,
          body: <></>,
        };
    }
  }, [modal]);

  return (
    <div className="EditorModals">
      <Modal isOpen={modal} title={renderContent.title}>
        <div className={`Modal__body ${modal}`}>{renderContent.body}</div>
      </Modal>
    </div>
  );
};
