import { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { ConnectOptions, connect } from "twilio-video";
import "../../styles/components/_joining-room-modal.scss";
import "../../styles/components/_meeting-preview.scss";
import fetchData from "../../hooks/api/postData";
import { SetMeetingStream, setVideoMeetingData } from "../../store/video-meeting/assessment.actions";
import { setChatToken, setClient, setChannel, setUserIdentity } from "../../store/conversation/conversation.actions";
import {
  FormControl,
  FormControlLabel,
  FormGroup,
  InputLabel,
  MenuItem,
  Select,
  Switch,
} from "@mui/material";
import ReactPlayer from "react-player";
import { getEnv } from "@urecruits/api";
import VideoMeetLoader from "./Loader/VideoMeetLoader";
import { useDispatch, useSelector } from "react-redux";
import { videoMeetingSelectors } from "../../store/video-meeting/assessment.selectors";
import { Client } from "twilio-chat";
import { useHasPermission, AuthGuard } from "@ucrecruits/globalstyle/src/ucrecruits-globalstyle";

const MeetingPreview = ({ id, candidateId, jobId, meetingType, roomStatus }: { id: string, candidateId?: number, jobId?: number, meetingType?: string, roomStatus?: string }) => {

  const {checkUserPermission} = useHasPermission()
  const navigate = useNavigate();
  const [roomName, setRoomName] = useState(id);
  const [userName, setUserName] = useState(null);
  const [room, setRoom] = useState<any>(null);
  const [isVideoModalOpen, setIsVideoModalOpen] = useState(false);
  const [minimizeScreen, setMinimizeScreen] = useState(false);
  const [participantLength, setParticipantLength] = useState(1);
  const [participants, setParticipants] = useState<any[]>([]);
  const [isLocalCameraEnable, setIsLocalCameraEnable] = useState(true);
  const [isLocalAudioEnable, setIsLocalAudioEnable] = useState(true);
  const [videoEnabled, setVideoEnabled] = useState(true);
  const [audioEnabled, setAudioEnabled] = useState(true);
  const [loader, setLoader] = useState(false)
  const [stream, setStream] = useState(null);
  const videoRef = useRef(null);
  const [isLoaderVisible, setIsLoaderVisible] = useState(false);
  const params = useLocation();
  const [errorMessage,setErrorMessage] = useState(null)
  const { API_RECRUITMENT } = getEnv()
  const dispatch = useDispatch()
  const streamStatus = useSelector(videoMeetingSelectors.getMeetingStream);

  const state: any = (params.state)
  // let stateCandidateId;
  // let stateJobId
  // let roomStatus
  // let type
  // for (const key in state) {
  //   if (state.hasOwnProperty(key)) {
  //     if (key === "candidateId") {
  //       stateCandidateId = state[key];
  //     } else if (key === "jobId") {
  //       stateJobId = state[key];
  //     } else if (key === "type") {
  //       type = state[key];
  //     } else if (key === "roomStatus") {
  //       roomStatus = state[key];
  //     }
  //   }
  // }

  const createChatChannel = async () => {
    const { token: conversationToken, channel } = await fetchData(
      // `https://recruitment-micro.urecruits.com/api/twilio/join-chat?channelName=${id}&userName=${userName}`
      `${API_RECRUITMENT}/api/twilio/join-chat?channelName=${id}&userName=${userName}`
    );
    dispatch(setUserIdentity(userName));
    handleConnectChat({ conversationToken, channel, userName });
    dispatch(setChatToken({ conversationToken, channel }));
  };

  const handleConnectChat = async (token) => {
    const client = new Client(token?.conversationToken);
    dispatch(setClient(client));
    let conversation;
    let members = [];
    client
      .getChannelBySid(token.channel.sid)
      .then((conversations) => {
        conversation= conversations
        dispatch(setChannel(conversation));
          return conversation.getMembers().then((conversationMembers) => {
            members=conversationMembers
            return {
              conversationMembers
          }});
       
      })
      .catch((err)=>{
        conversation.join();
        return Promise.resolve();
      })
      .then(() => {
        const userId = token.userName; // Assuming `identity` contains the user id
        const isMember = members.length != 0 && members.some(member => member.identity === userId);
        if (!isMember || members.length === 0) {
          return conversation.join();
        } else {
          return conversation;
        }
      })
      .then(() => {
        console.log("Successfully joined the conversation");
      })
      .catch((error) => {
        console.error("Error joining the conversation:", error);
      });
  };

  const createRoom = async (token: string, roomName: any) => {
    try {
      const twilioRoom = await connect(token, {
        name: roomName,
        dominantSpeaker: false,
        audio: true,
        video: true,
      } as ConnectOptions);
      setRoom(twilioRoom);
      dispatch(setVideoMeetingData({ isVideoModalOpen: true, room: twilioRoom, isAudioEnable: isLocalAudioEnable, isVideoEnable: isLocalCameraEnable, }))
    } catch (error) {
      console.log(error);
    }
  };

  const getUser = async () => {
    try {
      const result = await fetchData(`${API_RECRUITMENT}/api/user`);
      if (result) {
        {
          setUserName(`${result.firstname} ${result.lastname}`);
        }
      }
    } catch (error) {
    }
  };

  useEffect(() => {
    checkUserPermission(['recruiter','candidate']) &&getUser();
  }, []);

  const handleCreateButton = async () => {
    try {
      setIsLoaderVisible(true);
      const token = await fetchData(
        `${API_RECRUITMENT}/api/twilio/create-room?candidateId=${candidateId}&jobId=${jobId}&roomId=${id}&userName=${userName}`
      );
      if (token) {
        await createRoom(token, roomName);
        setIsVideoModalOpen(true);
        setIsLoaderVisible(false);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (room) {
      dispatch(SetMeetingStream(true))
      const participantArray = Array.from(room.participants.values(), (p) => ({
        participant: p,
      }));
      setParticipantLength(participantArray.length + 1);
      setParticipants(participantArray);
      const handleParticipantConnected = (participant) => {
        const remoteParticiapantLength = room?.participants.size;
        setParticipantLength((prev) => prev + 1);
        setParticipants((prevParticipants) => {
          return [...prevParticipants, { participant: participant }];
        });
      };
      const handleParticipantDisconnected = (participant) => {
        const remoteParticiapantLength = room?.participants.size;
        setParticipantLength((prev) => prev - 1);
        setParticipants((prevParticipants) => {
          return prevParticipants.filter((p) => p.participant !== participant);
        });
      };
      room.on("participantConnected", handleParticipantConnected);
      room.on("participantDisconnected", handleParticipantDisconnected);
      return () => {
        room.off("participantConnected", handleParticipantConnected);
        room.off("participantDisconnected", handleParticipantDisconnected);
        dispatch(SetMeetingStream(false))
      };
    }
  }, [room]);

  const stopVideoOnly = () => {
    setVideoEnabled((prev) => !prev);
    if (videoRef.current) {
      const videoTracks = videoRef.current
        ?.getInternalPlayer()
        ?.video?.srcObject?.getVideoTracks();
      if (videoTracks) {
        videoTracks.forEach((track) => {
          track.enabled = false;
        });
      }
    }
    setIsLocalCameraEnable((prev) => (prev = false));
  };

  const stopAudioOnly = () => {
    setAudioEnabled((prev) => !prev);
    const audioTracks = stream?.getAudioTracks();
    if (audioTracks) {
      audioTracks.forEach((track) => {
        track.enabled = false;
      });
    }
    setIsLocalAudioEnable((prev) => (prev = false));
  };

  useEffect(() => {
    if (isVideoModalOpen) {
      const videoTracks = videoRef.current
        ?.getInternalPlayer()
        ?.video?.srcObject?.getVideoTracks();
      const audioTracks = stream?.getAudioTracks();

      if (videoTracks) {
        videoTracks.forEach((track) => {
          track.enabled = !videoEnabled;
        });
      }
      if (audioTracks) {
        audioTracks.forEach((track) => {
          track.enabled = !audioEnabled;
        });
      }
    }
  })
  useEffect(() => {
    if (userName === null) {
      setLoader(true)
    } else {
      setLoader(false)
    }
  }, [userName])

  const startVideoAndAudio = () => {
    const constraints = {
      video: videoEnabled,
      audio: audioEnabled,
    };

    if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
      navigator.mediaDevices
        .getUserMedia(constraints)
        .then((userStream) => {
          setStream(userStream);
        })
        .catch((error) => {
          console.error("Error accessing camera and microphone:", error);
          setErrorMessage("Camera and microphone access is required to join the meeting. Please enable permissions and try again.")
        });
    } else {
      console.error("getUserMedia is not supported on this browser.");
    }
  };

  function disableMediaAccess() {
    if (stream) {
      const tracks = stream.getTracks();
      tracks.forEach((track) => {
        track.stop();
      })
    }
  }
  useEffect(() => {
    if (streamStatus === false) {
      disableMediaAccess()
    }
  }, [streamStatus])

  useEffect(() => {
    startVideoAndAudio();
  }, []);

  return (
    <AuthGuard module={['recruiter','candidate']}>
      <div className="full-background">
        {!minimizeScreen && <div className="modal-content1">
          <div className="minimize-screen1">
            <div className="video-container">
              <div className="video">
                {videoEnabled && (
                  <ReactPlayer
                    ref={videoRef}
                    url={stream}
                    width="100%"
                    height="100%"
                    playing={videoEnabled}
                    muted={true}
                  />
                )}

              </div>
            </div>
            {!!errorMessage && <p className="permission-container__error">{errorMessage}</p>}
            <div className="permission-container">
              <div className="select-container">
                <div className="action-card">
                  <InputLabel id="video-label">Video</InputLabel>
                  <FormControl variant="outlined" size="small" fullWidth>
                    <Select id="video-select" value={"inbuilt camera"}>
                      <MenuItem value={"inbuilt camera"} selected>
                        Integrated Camera
                      </MenuItem>
                    </Select>
                  </FormControl>
                </div>
                <div className="action-card">
                  <InputLabel id="audio-label">Audio</InputLabel>
                  <FormControl variant="outlined" size="small" fullWidth>
                    <Select
                      id="audio-select"
                      value={"Built-in Audio Analog Stereo"}
                    >
                      <MenuItem value={"Built-in Audio Analog Stereo"}>Built-in Audio Analog Stereo</MenuItem>
                    </Select>
                  </FormControl>
                </div>
              </div>
              <div className="radio-container">
                <FormGroup aria-label="position" row>
                  <FormControlLabel
                    value={videoEnabled}
                    onChange={() => stopAudioOnly()}
                    control={<Switch color="primary" defaultChecked />}
                    label="Start with audio on"
                    labelPlacement="top"
                  />
                  <FormControlLabel
                    value={audioEnabled}
                    onClick={() => stopVideoOnly()}
                    control={<Switch color="primary" defaultChecked />}
                    label="Start with video on"
                    labelPlacement="top"
                  />
                </FormGroup>
              </div>
              <div className="button-group">
                <button
                  type="button"
                  className="cancel-button"
                  onClick={() => {
                    navigate("/");
                    setStream(null);
                    disableMediaAccess();
                  }}>
                  Cancel
                </button>
                <button
                disabled={!!errorMessage}
                  onClick={async () => {
                    id && await handleCreateButton();
                    await createChatChannel()
                    // (meetingType !== "Technical/Coding Assessment") && await createChatChannel()
                    setIsVideoModalOpen(true);
                    disableMediaAccess();
                  }}
                  type="button"
                  className="button c-btn c-btn--green">
                  {id && roomStatus !== "not_initialized" ? "Join Meeting" : "Start Meeting"}
                </button>
              </div>
            </div>
          </div>
        </div>}
      </div>
      {loader && <VideoMeetLoader />}
    </AuthGuard>
  );
};

export default MeetingPreview;
