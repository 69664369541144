import { DataTypes } from "../../../types/assessmentTask";
import { LanguageSetting, LanguageSettings } from "./type";
// TODO: use enum instead of object
const languageSettings: LanguageSettings = {
  squareEndsWithComma: {
    starter: "[",
    end: "]",
    separator: ",",
  },
  curlyEndsWithComma: {
    starter: "{",
    end: "}",
    separator: ",",
  },
  roundEndWithSpace: {
    starter: "(",
    end: ")",
    separator: " ",
  },
};

const getSetting =(languageId):LanguageSetting=>{
  switch(languageId){
    case 93:
    case 63: return languageSettings.squareEndsWithComma;
    case 91: 
    case 62: return languageSettings.curlyEndsWithComma;
    case 46: return languageSettings.roundEndWithSpace;
    default: return languageSettings.squareEndsWithComma;
  }
}

export function getArrayInputs(javscriptArray, languageId,type=''): any {
  const languageSetting= getSetting(languageId);
  const elements_sequence = JSON.parse(javscriptArray).map(item => type ==  DataTypes.ARRAY_STRING ? `"${item}"` : item).join(languageSetting.separator);
  const array_string = languageSetting.starter+elements_sequence+languageSetting.end;
  return { array_string : array_string ,  array_lenght : array_string.split(languageSetting.separator).length};
}
