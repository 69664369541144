import {
  RemoteSelectionManager,
  RemoteCursorManager,
  EditorContentManager,
} from "@convergencelabs/monaco-collab-ext";

import * as monaco from "monaco-editor";
import { Option as OptionType } from "../../components/_elements/CodingSelect";

export interface State {
  editor: monaco.editor.IStandaloneCodeEditor | null;
  remoteSelectionManager: RemoteSelectionManager | null;
  remoteCursorManager: RemoteCursorManager | null;
  contentManager: EditorContentManager | null;
  isReadOnly: boolean;
  localAction: any;
  participants: any[];
  localParticipant: any;
  roomId: number | null;
  modal: any | null;
  settings: EditorSetings;
  markers: monaco.editor.IMarkerData[] | null;
  modalClose:boolean;
  userRole:any | null
  userName:any | null,
  outPut: any |null,
  currentUser: any | null
}

export enum EditorActionsEnum {
  selection = "selection",
  insert = "insert",
  delete = "delete",
  replace = "replace",
  dispose = "dispose",
  initial = "initial",
}

export enum PlaybackActionsEnun {
  play = "play",
  start = "start",
  pause = "pause",
  stop = "stop",
  reset = "reset",
}

export enum CodingModalsEnum {
  deadline = "deadline",
  inProgress = "inProgress",
  invite = "invite",
  congratulations = "congratulations",
  completed = "completed",
  settings = "settings",
  keyboardShortcuts = "keyboardShortcuts",
  listOfQuestions = "listOfQuestions",
  endInterview = "endInterview",
  interviewEnded = "interviewEnded",
  leaveMeeting="leaveMeeting",
}

export interface RecivedDataExample {
  user: ParticipantExample;
  action: EditorActionsEnum;
  data: {
    cursorPosition: number;
    startSelection: number;
    endSelection: number;
    index: number;
    text: string;
    length: number;
  };
  //....
}

export interface ParticipantExample {
  id: string;
  color: string;
  name: string;
  status: string;
  //....
}

export interface ParticipantForEditor {
  user: ParticipantExample;
  cursor: any;
  selection: any;
}

export interface EditorSetings {
  autoBrackers: boolean;
  minimap: boolean;
  codeCompletion: boolean;
  errorHighlightning: boolean;
  colorScheme: colorSchemeEnun;
  view: viewEnum;
  mode: OptionType;
  fontSize: OptionType;
  tabSpacing: OptionType;
}

export enum colorSchemeEnun {
  light = "light",
  dark = "dark",
}

export enum viewEnum {
  left = "left",
  right = "right",
}

export enum editorModeEnun {
  vim = "vim",
  emacs = "emacs",
  vs = "vscode",
}
