import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";

import { assessmentSelectors } from "../../store/assessment/assessment.selectors";
import { setIsSnackBarOpen } from "../../store/video-meeting/assessment.actions";
import { videoMeetingSelectors } from "../../store/video-meeting/assessment.selectors";
import { footerSelectors } from "../../store/footer/footer.selectors";
import { editorSelectors } from "../../store/editor/editor.selectors";

import { Problem } from "./Problem";
import { Terminal } from "./Terminal";
import { TestCases } from "./testCases";

export const TaskDescription = () => {
  const dispatch = useDispatch();

  const { assessmentTask, isOnline, activeTask } = useSelector(assessmentSelectors.getAllState);
  const { outPut, isReadOnly } = useSelector(editorSelectors.getAllState);
  const { status, message } = useSelector(videoMeetingSelectors.getIsSnackBarOpen);
  const { isTerminalLoading } = useSelector(footerSelectors.getAllState);


  const [liveCodingData, setLiveCodingData] = useState([]);
  const [isOffline, setIsOffline] = useState(false);
  const [selectedTabIndex, setSelectedTabIndex] = useState(0);
  useEffect(() => {
    if (activeTask?.data?.testcases) {
      setIsOffline(true)
    } else {
      setIsOffline(false)
    }
  }, [activeTask])

  useEffect(() => {
    if ((isOnline || isReadOnly) && assessmentTask) {
      setLiveCodingData([assessmentTask]);
    }
  }, [isOnline, assessmentTask, isReadOnly]);

  useEffect(() => {
    if (outPut) {
      setSelectedTabIndex(1);
    }
  }, [outPut]);

  useEffect(() => {
    if (isTerminalLoading && !isReadOnly && selectedTabIndex !== 1) {
      setSelectedTabIndex(1)
    }
  }, [isTerminalLoading])


  return (
    <div className="taskDescription">
      <Tabs selectedIndex={selectedTabIndex} onSelect={(index) => setSelectedTabIndex(index)}>
        <TabList>
          <Tab>Problem</Tab>
          {!isReadOnly && <Tab>Terminal</Tab>}
          {isOffline && <Tab>Test Cases</Tab>}
        </TabList>

        <TabPanel>
          <Problem problems={isOnline && !isReadOnly ? liveCodingData : !isOffline&& isReadOnly && liveCodingData.length > 0 ? liveCodingData : assessmentTask?.questions} />
        </TabPanel>
        {!isReadOnly &&
          <TabPanel>
            <Terminal />
          </TabPanel>
        }
        {isOffline && (
          <TabPanel>
            <TestCases testCases={activeTask?.data?.testcases} outputType={activeTask?.data?.outputType} testCaseInputs={activeTask?.data?.testCaseInputs} />
          </TabPanel>
        )}
      </Tabs>

      <div></div>
      <Snackbar
        open={status}
        autoHideDuration={3000}
        onClose={() => dispatch(setIsSnackBarOpen({ status: false, message: "" }))}
      >
        <MuiAlert
          elevation={6}
          variant="filled"
          onClose={() => dispatch(setIsSnackBarOpen({ status: false, message: "" }))}
          style={{ backgroundColor: "#099C73" }}
        >
          {message}
        </MuiAlert>
      </Snackbar>
    </div>
  );
}
