import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Split from "react-split-grid";
import cn from "classnames";
//components
import Header from "../components/header";
import { Editor } from "../components/code-editor";
import { TaskDescription } from "../components/task-description";
//store
import { setReadOnly } from "../store/editor/editor.actions";
import {
  setAssessmentTask,
  setIsOnline,
  resetAssessmentState,
} from "../store/assessment/assessment.actions";
import { editorSelectors } from "../store/editor/editor.selectors";
import { assessmentSelectors } from "../store/assessment/assessment.selectors";
import { videoMeetingSelectors } from "../store/video-meeting/assessment.selectors";

//hooks
import useSettings from "../hooks/editor/use-settings";
import { useWindowSize } from "../hooks/common/use-window-size";
//types
import * as types from "../types/editor";
import JoinRoom from "../components/video-meeting/JoinRoom";
import MeetingPreview from "../components/video-meeting/meetingPreview";
import { useLocation, useParams } from "react-router-dom";
import fetchData from "../hooks/api/postData";
import { getEnv } from "@urecruits/api";
import { SetStream } from "../store/video-meeting/assessment.actions";
import { useHasPermission, AuthGuard, DesktopOnlyPage } from "@ucrecruits/globalstyle/src/ucrecruits-globalstyle";
const {API_RECRUITMENT,API_ASSESSMENT}=getEnv()


export const LiveCodingPage = (props) => {

  const {checkUserPermission} = useHasPermission()

  const dispatch = useDispatch();
  const { width } = useWindowSize();
  const { isOnline } = useSelector(assessmentSelectors.getAllState);
  const { settings } = useSelector(editorSelectors.getAllState);
  const [isLoading, setIsLoading] = useState(true);
  const [columns, setColumns] = useState("400px 1px 1fr");
  const {room} = useParams(); 
  const params = useLocation();

  const state:any=(params.state)
  // let candidateId;
  // let jobId
  const [candidateId,setCandidateId] = useState<number>();
  const [jobId,setJobId] = useState<number>();
  const [meetingType,setMeetingType] = useState<string>();
    
  const {
    videoMeetingData,isEndInterView
  } = useSelector(videoMeetingSelectors.getAllState);
  useSettings();

  const getEventByRoom = async () => {
    checkUserPermission(["recruiter","candidate"]) && await fetchData(`${API_ASSESSMENT}/api/calendar/room-event/${room}`)
    .then((res)=>{
      if(res){
        setCandidateId(res.candidateId);
        setJobId(res.jobId);
        setMeetingType(res.description)
      }
    })
  }
  useEffect(()=>{
    if(!state) getEventByRoom();
    else{
      for (const key in state) {
        if (state.hasOwnProperty(key)) {
          if (key === "candidateId") {
            setCandidateId(state[key]);
          } 
          else if (key === "jobId") {
            setJobId(state[key]);
          }
          else if (key === "type") {
            setMeetingType(state[key]);
          }
        }
      }
    }
  },[])

  useEffect(() => {
    if (
      videoMeetingData?.isVideoModalOpen === false &&
      state === null &&
      !jobId
    ) {
      dispatch(SetStream(null));
      // dispatch(setModal(types.CodingModalsEnum.completed));
    }

    const handleBeforeUnload = () => {
      return true;
    };

    window.onbeforeunload = handleBeforeUnload;

    return () => {
      window.onbeforeunload = null;
    };
  }, []);

  useEffect(() => {
    jobId &&checkUserPermission(["recruiter","candidate"]) &&
      fetchData(`${API_RECRUITMENT}/api/job/public-job/${jobId}`).then(
        (res) => {
          if (res?.workflow) {
            const id = res?.workflow?.liveCodingId;
            fetchData(`${API_ASSESSMENT}/api/live-coding/${id}`).then(
              (data) => {
                dispatch(setAssessmentTask(data));
              }
            );
          }
        }
      );
    dispatch(setReadOnly(false)); // if true (only for review code playback)
    dispatch(setIsOnline(true));
    setIsLoading(false);

    return () => {
      dispatch(resetAssessmentState());
    };
  }, [jobId]);

  useEffect(()=>{
    if(videoMeetingData?.room?.localParticipant){
      const data=videoMeetingData?.room
      if (videoMeetingData?.isVideoEnable) {
        data.localParticipant.videoTracks.forEach(
          (publication) => {
            publication.track.enable();
          }
        );
      }
      if (!videoMeetingData?.isVideoEnable) {
        data?.localParticipant?.videoTracks?.forEach(
          (publication) => {
            publication?.track?.disable();
          }
        );
      }
    }
  },[videoMeetingData?.isVideoModalOpen===true])
  

  const handleDrag = (direction, track, style) => {
    setColumns(style);
  };

  //columnMinSizes
  const columnMinSizes = useMemo(() => {
    const editorWidth = width - width * 0.4;
    const taskDescriptionWidth = 400;
    if (settings.view === types.viewEnum.left) {
      setColumns("340px 1px 1fr");
      return { [0]: taskDescriptionWidth, [2]: editorWidth };
    } else {
      setColumns("1fr 1px 340px");
      return { [0]: editorWidth, [2]: taskDescriptionWidth };
    }
  }, [settings.view, width]);

  if (isLoading) {
    return <h1>Loading...</h1>;
  }

  
  return (
    <>
    {
      width < 991 ?
      <DesktopOnlyPage/>
      :
      <AuthGuard module={['recruiter','candidate']}>
    {videoMeetingData?.isVideoModalOpen===false && <MeetingPreview id={room} candidateId={candidateId} jobId={jobId} meetingType={meetingType} />}
    {videoMeetingData?.isVideoModalOpen===true && 
    <>
    <JoinRoom roomId={videoMeetingData?.room?.name} roomObj={videoMeetingData?.room} isVideoEnable={videoMeetingData?.isVideoEnable} isAudioEnable={videoMeetingData?.isAudioEnable}/>
    <div className="codding">
      <div className="codding__wrapper">
        <Header />
        <div className={cn("codding__content-wrapper", settings.view)}>
          <Split
            gridTemplateColumns={columns}
            onDrag={handleDrag}
            cursor="col-resize"
            columnMinSizes={columnMinSizes}
            // @ts-ignore
            render={({ getGridProps, getGutterProps }) => (
              <div
                className={`split-grid ${settings.view}`}
                {...getGridProps()}
              >
                <TaskDescription />
                <div className="gutterGrid" {...getGutterProps("column", 1)} />
                <Editor isWidthChanged={columns} />
              </div>
            )}
          />
          {/* {isOnline && <MeetingSidebar />} */}
        </div>
      </div>
    </div>
    </>
    }
    </AuthGuard>
    }
    </>
   
  );
};
